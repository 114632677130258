var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormWrapper',{staticClass:"password-settings-container",attrs:{"whitelisted":true}},[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form-container mt-3",attrs:{"onsubmit":"event.preventDefault()","autocomplete":"off"}},[_c('FormRow',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('form_label_new_password'))+": ")]},proxy:true},{key:"inputs",fn:function(){return [_c('ValidationProvider',{staticClass:"inputs-container items-container",attrs:{"vid":"newPassword","mode":"lazy","name":_vm.$t('password'),"rules":_vm.validationRules.password,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ErrorTooltip',{attrs:{"errors":errors}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formItems.password),expression:"formItems.password"}],ref:"passwordInput",staticClass:"input-size input-style",attrs:{"type":"password","placeholder":_vm.$t('form_label_new_password')},domProps:{"value":(_vm.formItems.password)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.formItems, "password", $event.target.value)},_vm.onChange]}})])]}}],null,true)})]},proxy:true}],null,true)}),_c('FormRow',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('form_label_new_password_confirm'))+": ")]},proxy:true},{key:"inputs",fn:function(){return [_c('ValidationProvider',{staticClass:"inputs-container items-container",attrs:{"mode":"lazy","vid":"confirmPassword","name":_vm.$t('confirmPassword'),"rules":_vm.validationRules.confirmPassword,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ErrorTooltip',{attrs:{"errors":errors}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formItems.passwordConfirm),expression:"formItems.passwordConfirm"}],ref:"passwordInput",staticClass:"input-size input-style",attrs:{"type":"password","placeholder":_vm.$t('form_label_new_password_confirm')},domProps:{"value":(_vm.formItems.passwordConfirm)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.formItems, "passwordConfirm", $event.target.value)},_vm.onChange]}})])]}}],null,true)})]},proxy:true}],null,true)}),_c('FormRow',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('form_label_new_password_hint'))+": ")]},proxy:true},{key:"inputs",fn:function(){return [_c('ValidationProvider',{staticClass:"inputs-container items-container",attrs:{"vid":"hint","name":_vm.$t('hint'),"rules":_vm.validationRules.message,"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ErrorTooltip',{attrs:{"errors":errors}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formItems.passwordHint),expression:"formItems.passwordHint"}],staticClass:"input-size input-style",attrs:{"placeholder":_vm.$t('form_label_new_password_hint')},domProps:{"value":(_vm.formItems.passwordHint)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.formItems, "passwordHint", $event.target.value)},_vm.onChange]}})])]}}],null,true)})]},proxy:true}],null,true)}),_c('div',{staticClass:"form-row text-right"},[_c('button',{staticClass:"button-style inverted-button pl-2 pr-2",attrs:{"type":"submit","disabled":_vm.isConfirmButtonDisabled},on:{"click":function($event){return handleSubmit(_vm.onSubmit)}}},[_vm._v(" "+_vm._s(_vm.$t('confirm'))+" ")])])],1)]}}])}),(_vm.hasAccountUnlockModal)?_c('ModalFormProfileUnlock',{attrs:{"visible":_vm.hasAccountUnlockModal,"on-success":_vm.onAccountUnlocked},on:{"close":function($event){_vm.hasAccountUnlockModal = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }