






































import ModalConfirmTs from './ModalConfirmTs';

export default class ModalConfirm extends ModalConfirmTs {}
