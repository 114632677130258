
































































































import { Component, Vue } from 'vue-property-decorator';
import { NetworkType } from 'symbol-sdk';
// child components
import FormWrapper from '@/components/FormWrapper/FormWrapper.vue';
import FormLabel from '@/components/FormLabel/FormLabel.vue';
// configuration
import { appConfig } from '@/config';
import { feesConfig } from '@/config';
import { networkConfig } from '@/config';
import packageConfig from '@/../package.json';
import packageLockConfig from '@/../package-lock.json';
import { mapGetters } from 'vuex';
import { NetworkModel } from '@/core/database/entities/NetworkModel';
import { URLInfo } from '@/core/utils/URLInfo';

@Component({
    components: {
        FormWrapper,
        FormLabel,
    },
    computed: {
        ...mapGetters({
            networkModel: 'network/networkModel',
            networkType: 'network/networkType',
            currentPeer: 'network/currentPeer',
        }),
    },
})
export default class AboutPage extends Vue {
    private networkModel: NetworkModel;

    public currentPeer: URLInfo;

    public configs = {
        package: packageConfig,
        packageLock: packageLockConfig,
        app: appConfig,
        fees: feesConfig,
        network: networkConfig,
    };
    public types = NetworkType;
    public networkType: NetworkType;

    public isNetworkType(type): boolean {
        return networkConfig[this.networkType].defaultNetworkType === type;
    }

    public get generationHash(): string {
        return this.networkModel.generationHash;
    }

    public get nodeLink(): string {
        return `${this.currentPeer}`;
    }
}
