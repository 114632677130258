


























































































import { PeerSelectorTs } from './PeerSelectorTs';

export default class PeerSelector extends PeerSelectorTs {}
