
















import { DeleteProfileButtonTs } from './DeleteProfileButtonTs';
export default class DeleteProfileButton extends DeleteProfileButtonTs {}
