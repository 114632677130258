








import { LogoutButtonTs } from './LogoutButtonTs';
export default class LogoutButton extends LogoutButtonTs {}
