














































































import { FormGeneralSettingsTs } from './FormGeneralSettingsTs';
export default class FormGeneralSettings extends FormGeneralSettingsTs {}
