


































































import { PageLayoutTs } from './PageLayoutTs';
export default class PageLayout extends PageLayoutTs {}
