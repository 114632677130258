

































import { ModalNetworkNotMatchingProfileTs } from './ModalNetworkNotMatchingProfileTs';
export default class ModalNetworkNotMatchingProfile extends ModalNetworkNotMatchingProfileTs {}
