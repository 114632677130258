










import { LanguageSelectorTs } from './LanguageSelectorTs';

export default class LanguageSelector extends LanguageSelectorTs {}
