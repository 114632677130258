




































































































import { FormProfilePasswordUpdateTs } from './FormProfilePasswordUpdateTs';
export default class FormProfilePasswordUpdate extends FormProfilePasswordUpdateTs {}
