// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../resources/fonts/NotoSans-Bold.ttf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../resources/fonts/NotoSans-ExtraBold.ttf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../resources/fonts/NotoSans-Light.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../resources/fonts/NotoSans-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../resources/fonts/NotoSans-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../resources/fonts/NotoSans-SemiBold.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
exports.push([module.id, "@font-face{font-family:noto-sans-bold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-extrabold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-light;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-medium;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-regular;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");font-weight:400;font-style:normal}@font-face{font-family:noto-sans-semibold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");font-weight:400;font-style:normal}.settings-modal-container[data-v-998dd23e] .ivu-modal{height:6.8rem}.settings-modal-container[data-v-998dd23e] .ivu-modal .auto-complete-size{width:100%}.settings-modal-container .settings-container[data-v-998dd23e]{display:grid;height:100%;grid-template-columns:20% 80%;padding:0!important;margin:-.15rem -.3rem}[data-v-998dd23e] .ivu-modal-body{max-height:none;overflow-y:hidden;height:6.8rem;padding:.15rem .3rem}.nav-links-wrapper .header-container[data-v-998dd23e]{padding-left:.25rem;padding-top:.25rem;padding-bottom:.45rem}.nav-links-wrapper .header-container .title-style[data-v-998dd23e]{color:#44004e;font-size:.18rem;font-family:noto-sans-semibold}.nav-links-wrapper .header-container .nav-wrapper[data-v-998dd23e]{height:100%}", ""]);
// Exports
module.exports = exports;
