


































































import { FormAdvancedSettingsTs } from './FormAdvancedSettingsTs';
export default class FormAdvancedSettings extends FormAdvancedSettingsTs {}
