var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'symbol-tab-container',
        _vm.direction === 'horizontal'
            ? _vm.translationPrefix === 'tab_contact_'
                ? ['horizontal', 'line', 'smaller-tab-offset']
                : 'horizontal'
            : 'vertical' ]},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,class:_vm.direction === 'horizontal' ? ['empty'] : ['symbol-tab-nav', index === _vm.currentItemIndex ? 'active-item' : 'inactive-item']},[_c('span',{class:[
                'nav-item',
                index === _vm.currentItemIndex ? _vm.activeLinkCssClass : _vm.inactiveLinkCssClass,
                index === 0 ? '' : _vm.borderCssClass ],on:{"click":function($event){return _vm.$emit('selected', index)}}},[_c('p',{class:index === _vm.currentItemIndex ? _vm.activeBorder : _vm.inActiveBorder},[_vm._v(_vm._s(_vm.$t(_vm.translationPrefix + item.toLowerCase())))])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }