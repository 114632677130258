











import { AccountLinksTs } from './AccountLinksTs';

export default class AccountLinks extends AccountLinksTs {}
