






























import { PageNavigatorTs } from './PageNavigatorTs';
import { Component } from 'vue-property-decorator';
import PeerSelector from '@/components/PeerSelector/PeerSelector.vue';
// @ts-ignore
@Component({
    components: { PeerSelector },
})
export default class PageNavigator extends PageNavigatorTs {}
