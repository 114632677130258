






















// external dependencies
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
    computed: {
        ...mapGetters({
            logs: 'diagnostic/logs',
        }),
    },
})
export default class ModalDebugConsole extends Vue {
    /**
     * Modal title
     * @type {string}
     */
    @Prop({ default: '' }) title: string;

    /**
     * Modal visibility state from parent
     * @type {string}
     */
    @Prop({ default: false }) visible: boolean;

    /**
     * Internal visibility state
     * @type {boolean}
     */
    public get show(): boolean {
        return this.visible;
    }

    /**
     * Emits close event
     */
    public set show(val) {
        if (!val) {
            this.$emit('close');
        }
    }

    public getLevel(entry): string {
        return entry.level === 1 ? 'INFO' : entry.level === 2 ? 'DEBUG' : entry.level === 3 ? 'WARNING' : 'ERROR';
    }

    public getTime(entry): string {
        return entry.time.toLocaleString();
    }

    /**
     * Hook called when component is mounted
     */
    mounted() {
        // Scrolls logs div to bottom
        Vue.nextTick().then(() => {
            const container = this.$el.querySelector('#logs-container');
            container.scrollTop = container.scrollHeight;
        });
    }
}
