









































import { ModalSettingsTs } from './ModalSettingsTs';
export default class ModalSettings extends ModalSettingsTs {}
