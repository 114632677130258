














import { SettingsTs } from './SettingsTs';
export default class Settings extends SettingsTs {}
